import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import news8pic1 from '../assets/images/1003.jpg'
import ios from '../assets/images/app-store.svg'
import google from '../assets/images/google-play.svg'
const News8 = props => (
  <Layout>
    <Helmet>
      <title>【三健客與您一同積極抗疫，守護台灣】​</title>
      <meta name="description" content="三健客防疫" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>【三健客與您一同積極<br/> 抗疫，守護台灣】</h1>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news8pic1} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h3>因應疫情持續升溫，為落實防疫機制 保護每一位健客以及工作夥伴</h3>
          <h3>三健客決定自 #5月15日 至 #6月28日 暫時停止場館服務</h3>
          <p>
            各位健客們不需擔心 <br/> 所有現有會籍日期都將自今日起自動展延至6/28<br/> 
            請大家落實防疫，避免非必要性外出<br/>  外出時務必戴口罩、勤洗手落實防疫<br/> 
            各位健客在家防疫的同時 也請持續關注三健客粉專<br/> 
            我們將教你在家防疫也能居家健身的簡單方法 三健客與您一同積極配合防疫<br/> 
            守護台灣<br/>  造成不便，敬請見諒
          </p>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/3muscler/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/3musclers/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          <iframe
            src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2F&width=450&layout=standard&action=like&size=small&share=true&height=35&appId=3212226998840909"
            width="450"
            height="35"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allow="encrypted-media"
          ></iframe>
        </div>
      </section>
    </div>
  </Layout>
)

export default News8
